<template>
  <div>
    <div class="jumbotron">
      <h1 class="display-4">Licznik SocialMedia</h1>
      <p class="lead">Marzysz o liczniku obserwujących? Polubień? Na instagramie lub tiktoku? Tutaj taki znajdziesz i
        zakupisz! </p>
      <p>Zobacz wspierane przez nas
        <b-button v-b-modal.modal-2 size="sm" variant="secondary" pill>platformy</b-button>
      </p>
      <p>
        Zobacz gdzie i jak możesz
        <b-button v-b-modal.modal-1 size="sm" variant="secondary" pill>kupić licznik</b-button>
      </p>
      <hr class="my-4">
      <p></p>
      <p v-if="$store.state.token">
        <b-btn variant="primary" size="lg" :to="`/settings`" role="button">Przejdź do swojego licznika</b-btn>

      </p>
      <p v-else>
        <b-btn variant="success" size="lg" :to="`/login`" role="button">Zaloguj się na swoim urządzeniu</b-btn>

      </p>
    </div>
    <b-container fluid class="p-4 bg-dark">
      <b-row>
        <b-col cols="12">
          <h2 class="text-light">Przykładowe realizacje</h2>
        </b-col>
        <b-col sm="12" md="2" class="mt-5">
          <b-img thumbnail fluid src="https://picsum.photos/250/250/?image=54" alt="Image 1"></b-img>
        </b-col>
        <b-col sm="12" md="2" class="mt-5">
          <b-img thumbnail fluid src="https://picsum.photos/250/250/?image=54" alt="Image 1"></b-img>
        </b-col>
        <b-col sm="12" md="2" class="mt-5">
          <b-img thumbnail fluid src="https://picsum.photos/250/250/?image=54" alt="Image 1"></b-img>
        </b-col>
        <b-col sm="12" md="2" class="mt-5">
          <b-img thumbnail fluid src="https://picsum.photos/250/250/?image=54" alt="Image 1"></b-img>
        </b-col>
        <b-col sm="12" md="2" class="mt-5">
          <b-img thumbnail fluid src="https://picsum.photos/250/250/?image=54" alt="Image 1"></b-img>
        </b-col>
        <b-col sm="12" md="2" class="mt-5">
          <b-img thumbnail fluid src="https://picsum.photos/250/250/?image=54" alt="Image 1"></b-img>
        </b-col>
        <b-col sm="12" md="2" class="mt-5">
          <b-img thumbnail fluid src="https://picsum.photos/250/250/?image=54" alt="Image 1"></b-img>
        </b-col>
        <b-col sm="12" md="2" class="mt-5">
          <b-img thumbnail fluid src="https://picsum.photos/250/250/?image=54" alt="Image 1"></b-img>
        </b-col>
        <b-col sm="12" md="2" class="mt-5">
          <b-img thumbnail fluid src="https://picsum.photos/250/250/?image=54" alt="Image 1"></b-img>
        </b-col>
        <b-col sm="12" md="2" class="mt-5">
          <b-img thumbnail fluid src="https://picsum.photos/250/250/?image=54" alt="Image 1"></b-img>
        </b-col>
        <b-col sm="12" md="2" class="mt-5">
          <b-img thumbnail fluid src="https://picsum.photos/250/250/?image=54" alt="Image 1"></b-img>
        </b-col>
        <b-col sm="12" md="2" class="mt-5">
          <b-img thumbnail fluid src="https://picsum.photos/250/250/?image=54" alt="Image 1"></b-img>
        </b-col>
        <b-col sm="12" md="2" class="mt-5">
          <b-img thumbnail fluid src="https://picsum.photos/250/250/?image=54" alt="Image 1"></b-img>
        </b-col>
        <b-col sm="12" md="2" class="mt-5">
          <b-img thumbnail fluid src="https://picsum.photos/250/250/?image=54" alt="Image 1"></b-img>
        </b-col>
        <b-col sm="12" md="2" class="mt-5">
          <b-img thumbnail fluid src="https://picsum.photos/250/250/?image=54" alt="Image 1"></b-img>
        </b-col>
        <b-col sm="12" md="2" class="mt-5">
          <b-img thumbnail fluid src="https://picsum.photos/250/250/?image=54" alt="Image 1"></b-img>
        </b-col>
        <b-col sm="12" md="2" class="mt-5">
          <b-img thumbnail fluid src="https://picsum.photos/250/250/?image=54" alt="Image 1"></b-img>
        </b-col>
        <b-col sm="12" md="2" class="mt-5">
          <b-img thumbnail fluid src="https://picsum.photos/250/250/?image=54" alt="Image 1"></b-img>
        </b-col>
        <b-col sm="12" md="2" class="mt-5">
          <b-img thumbnail fluid src="https://picsum.photos/250/250/?image=54" alt="Image 1"></b-img>
        </b-col>
        <b-col sm="12" md="2" class="mt-5">
          <b-img thumbnail fluid src="https://picsum.photos/250/250/?image=54" alt="Image 1"></b-img>
        </b-col>

      </b-row>
    </b-container>
    <b-modal id="modal-1" title="Jak dokonać zakupu licznika?">
      <p class="my-4">Aby kupić licznik napisz na instagramie do
        <a href="https://www.instagram.com/miki_nik23/"
           rel="nofollow">@miki_nik23</a>
        w celu ustalenia szczegółów.</p>
    </b-modal>
    <b-modal id="modal-2" title="Wspierane platformy" size="lg">
      <b-table-simple hover small caption-top responsive>
        <caption>Lista platform, które obecnie są wspierane przez licznik</caption>
        <b-thead head-variant="dark">
          <b-tr>
            <b-th>Nazwa platformy</b-th>
            <b-th>Co może pokazywać licznik</b-th>
            <b-th>Data utworzenia</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr>
            <b-th>Tiktok</b-th>
            <b-td>Obserwacje / polubienia</b-td>
            <b-td>05.02.2021</b-td>
          </b-tr>
          <b-tr>
            <b-th>Instagram</b-th>
            <b-td>Obserwacje</b-td>
            <b-td>05.02.2021</b-td>
          </b-tr>
          <b-tr>
            <b-th>YouTube</b-th>
            <b-td>Subskrypcje</b-td>
            <b-td>05.02.2021</b-td>

          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-modal>
  </div>
</template>

<script>

export default {
  name: 'Home',
  data() {
    return {
      items: [
        {Platforma: 40, Wsparcie: 'Dickerson'},
        {age: 21, first_name: 'Larsen', last_name: 'Shaw'},
        {age: 89, first_name: 'Geneva', last_name: 'Wilson'},
        {age: 38, first_name: 'Jami', last_name: 'Carney'}
      ]
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.jumbotron {
  margin-bottom: 0;
}
</style>
